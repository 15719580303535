.App {
  text-align: left;
  max-width: 512px;
  margin: 0px auto auto;
}

.section {
  text-align: left;
  max-width: 512px;
  margin: 0px auto auto;
}